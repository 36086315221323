import './bootstrap';
import '../css/app.css';
import 'keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'
import {Livewire, Alpine} from '../../vendor/livewire/livewire/dist/livewire.esm';

import collapse from "@alpinejs/collapse";

Alpine.store("cart", {
    count: 0,
    cart: {},
    metadata: {},
    getTotal() {
        return Object.values(this.cart).reduce(
            (p, c) => p + c.product.price * c.quantity,
            0
        );
    },
    getTotalVat() {
        console.log(this.cart);
        return Object.values(this.cart).reduce(
            (p, c) =>
                p +
                c.product.price *
                c.quantity *
                ((c.product.vat_rate ?? 21) / 100),
            0
        );
    },
    getTotalByProduct(productId) {
        return (
            this.cart[productId].product.price * this.cart[productId].quantity
        );
    },
    setCart(cart) {
        cart.forEach((e) => {
            this.setProduct(e.product.id, e);
        });
    },
    setMetadata(metadata) {
        this.metadata = metadata;
        console.table(this.metadata);
    },
    setShippingCost(shippingCost) {
        this.shippingCost = shippingCost;
    },
    setProduct(productId, product) {
        this.cart[productId] = product;
    },
    removeProduct(productId) {
        this.deleteProduct(productId);
    },
    incrementProduct(productId) {
        this.setQuantity(productId, this.cart[productId].quantity + 1);
    },
    decrementProduct(productId) {
        this.setQuantity(productId, this.cart[productId].quantity - 1);
    },
    setQuantity(productId, quantity) {
        this.updateQuantity(productId, quantity);
    },
    setCount(count) {
        this.count = count;
    },
    loadCount() {
        window.axios
            .get(route("api.card-products.count"))
            .then(({data}) => {
                this.count = data.count;
            })
            .catch((error) => this.handleError(error));
    },
    updateQuantity(productId, quantity) {
        window.axios
            .put(route("api.card-products.update", productId), {
                quantity,
            })
            .then(({data}) => {
                this.cart[productId].quantity = Math.max(data.quantity, 0);
            })
            .catch((error) => this.handleError(error));
    },
    deleteProduct(productId) {
        window.axios
            .put(route("api.card-products.destroy", productId))
            .then(({data}) => {
                delete this.cart[productId];
            })
            .catch((error) => this.handleError(error));
    },
    updateMetadata(key, value) {
        window.axios
            .put(route("api.card-metadata.update", key), {value})
            .then(({data}) => {
                this.metadata[key] = value;
            })
            .catch((error) => this.handleError(error));
    },
    handleError(error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
    },
});

import productInput from "./commerce/product-input";

Alpine.data("productInput", productInput);

import cartInterface from "./commerce/cart-interface";

Alpine.data("cartInterface", cartInterface);

import multiSelect from "./../cms/js/multi-select";

Alpine.data("multiSelect", multiSelect);

import animateScrollTo from "animated-scroll-to";
import * as FilePond from "filepond";
import nl_NL from "filepond/locale/nl-nl";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";

FilePond.setOptions(nl_NL);
window.FilePond = FilePond;

window.FilePondPluginFileValidateSize = FilePondPluginFileValidateSize;

window.Alpine = Alpine;

Alpine.plugin(collapse);
// Alpine.plugin(Clipboard)

// Alpine.start();

Livewire.start()

window.animateScrollTo = animateScrollTo;


document.addEventListener('DOMContentLoaded', () => {
    const sliders = [...document.querySelectorAll('.keen-slider.default')]

    sliders.forEach((slider) => setupDefaultSlider(slider))
})

function setupDefaultSlider(slider) {
    const timeout = 5000

    const dotContainer = document.getElementById(slider.id + '-dots')

    const keenSlider = new KeenSlider(slider, {
        loop: true,
        centered: true,
        duration: 2000,
        slidesPerView: 1,
        created: () => {
            slider.classList.remove('opacity-0')
        },
        slideChanged(instance) {
            var slide = instance.details().relativeSlide
            setDotActive(dotContainer, slide)
        },
        dragStart: () => {
            clearInterval(sliderInterval)
        },
        dragEnd: () => {
            clearInterval(sliderInterval)
            sliderInterval = setInterval(() => {
                keenSlider.next()
            }, timeout)
        },
    })

    let sliderInterval = setInterval(() => {
        keenSlider.next()
    }, timeout)
}

function setDotActive(dotContainer, index) {
    [...dotContainer.children].forEach((dot, i) => {
        dot.classList.remove('bg-amber')
        dot.classList.add('bg-yellow-lightest')
        if (index === i) {
            dot.classList.remove('bg-yellow-lightest')
            dot.classList.add('bg-amber')
        }
    })
}

document.addEventListener('DOMContentLoaded', () => {
    const sliders = [...document.querySelectorAll('.keen-slider.no-timer')]

    sliders.forEach((slider) => setupNoTimerSlider(slider))
})

function setupNoTimerSlider(slider) {
    const dotContainer = document.getElementById(slider.id + '-dots')

    const keenSlider = new KeenSlider(slider, {
        loop: true,
        centered: true,
        duration: 2000,
        slidesPerView: 1,
        created: () => {
            slider.classList.remove('opacity-0')
        },
        slideChanged(instance) {
            var slide = instance.details().relativeSlide
            setDotActive(dotContainer, slide)
        },
    })
}

// function setDotActive(dotContainer, index) {
//     [...dotContainer.children].forEach((dot, i) => {
//         dot.classList.remove('bg-amber')
//         dot.classList.add('bg-lime')
//         if (index === i) {
//             dot.classList.remove('bg-lime')
//             dot.classList.add('bg-amber')
//         }
//     })
// }


document.addEventListener("DOMContentLoaded", function () {
    // window.livewire.on("urlChange", (url) => {
    //     history.pushState(null, null, url);
    // });
});
